.chat-interface {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 250px;
    background-color: #2d3f2d;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.sidebar-header .title {
    font-size: 24px;
    font-weight: bold;
}

.new-chat-button {
    background-color: #3d4f3d;
    color: white;
    margin: 20px 0;
}

.chat-history .history-title {
    font-size: 14px;
    color: #EFF6FF;
    margin-bottom: 10px;
}

.chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f0e8;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.model-switch button {
    margin-right: 8px;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.message.user .message-avatar {
    background-color: #3d4f3d;
}

.message.assistant .message-avatar {
    background-color: #d3d3d3;
}

.message-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.message-content {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    flex: 1;
}

.message.assistant .message-content {
    background-color: #f9f5ed;
}

.message-actions {
    margin-top: 8px;
    display: flex;
    gap: 5px;
}

.chat-footer {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.chat-input {
    flex: 1;
    margin-right: 10px;
}

.send-button {
    background-color: #0077b6;
    border: none;
    color: white;
}

.link-sidebar {
    width: 250px;
    background-color: #e8f0e8;
    padding: 20px;
}

.link-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

/* Override selected menu item */
.ant-menu-item-selected {
    background-color: transparent !important;
    /* Remove the green background */
    color: white !important;
    /* Ensure text stays white */
    border: none !important;
    /* Remove any border effects */
}

.ant-menu-item-active {
    background-color: transparent !important;
    /* Remove the green background */
    color: white !important;
    /* Ensure text stays white */
    border: none !important;
    /* Remove any border effects */
}

/* Override hover state to match non-active state */
.ant-menu-item:hover {
    background-color: transparent !important;
    /* No hover background */
    color: white !important;
    /* Keep hover text color consistent */
}

/* Ensure all menu items stay consistent */
.ant-menu-item {
    background-color: transparent !important;
    color: white !important;
}

.ant-layout.ant-layout-has-sider {
    padding: 0 !important;
    /* Removes any padding */
}

.ant-layout.ant-layout-has-sider {
    padding-top: 0 !important;
    /* Overrides the default top padding */
}
.sidebar {
    width: 250px;
    background: linear-gradient(
            rgba(0, 0, 0, 0.6), /* Adjust transparency here */
            rgba(0, 0, 0, 0.4)  /* Subtle fade for a softer effect */
        ),
        url('/background.jpeg'); /* Ensure correct path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


