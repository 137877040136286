.sidebar-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #3a4d3a;
}

.previous-chats {
    padding-top: 20px;
}

.ant-menu-item {
    color: white !important;
    font-size: 16px;
}

.ant-menu-item:hover {
    background-color: #3d4f3d !important;
}


.email-prompt-section {
    margin-top: 20px;
    text-align: center;
}

.feedback-message {
    font-size: 16px;
    font-weight: bold;
}