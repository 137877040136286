/* App Container */
.App {
  text-align: center;
}

/* Logo Styling */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Rotate Animation for Logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Only apply rotation animation if user prefers */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header Styling */
.App-header {
  background-color: #0077b6;
  /* Brand Blue */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  /* White text for contrast */
}

/* Link Styling */
.App-link {
  color: #ffb300;
  /* Gold color from logo */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.App-link:hover {
  color: #61dafb;
  /* Lighter blue for hover effect */
}

/* Demo Button (if applicable) */
.demo-button {
  background-color: #61dafb;
  /* Light blue to match link hover */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demo-button:hover {
  background-color: #0077b6;
  /* Darker blue for hover effect */
}

/* Upload Button Styling (if applicable) */
.upload-button {
  background-color: #ffb300;
  /* Gold color */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #ff8c00;
  /* Darker shade of gold for hover effect */
}

/* Background for Sections */
.section-background {
  background-color: #d4edda;
  /* Light green background to complement dumpster icon */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  color: #333;
  /* Dark text for readability */
}