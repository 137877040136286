/* General Page Styling */
.landing-page {
    position: relative;
    width: 100vw;
    /* Ensure it takes up the full width */
    height: 100vh;
    /* Ensure it takes up the full height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('/background.jpeg') no-repeat center center/cover;
    /* Adjust the background URL to a relative path for consistency */
    overflow: hidden;
    margin: 0;
    /* Remove any default margins */
    padding: 0;
    /* Remove any default padding */
    box-sizing: border-box;
    /* Prevent padding/margin from affecting the dimensions */
}

/* Fade Effect Overlay */
.fade-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 90%);
    z-index: 1;
}

/* Main Content Styling */
.landing-page-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #1f2937;
    padding: 5;
}

.landing-page-title {
    font-size: 3rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1.25rem;
}

.coral-ai-highlight {
    color: #2563eb;
    /* Highlight color for CoralAI */
}

.landing-page-subtitle {
    font-size: 1.25rem;
    color: #4b5563;
    margin-bottom: 2.5rem;
    padding: 0.5%;
}

/* Search Input Section */
.search-section {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 100%; 
    /* max-width: 500px; */
    /* position: relative; */
    /* left: 400px; */
    /* Set a max width for the input */
    padding: 0 1rem;
    /* Add padding for smaller screens */
}

.search-input {
    flex: 1;
    border-radius: 8px;
    font-size: 16px;
    padding: 0.5rem;
    border: 1px solid #ddd;
}

/* Footer Avatar */
.footer-avatar {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
}

.footer-avatar .ant-avatar {
    background-color: #2563eb;
    color: #fff;
}