body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  color: #333;
}

a {
  color: #00509e;
  text-decoration: none;
}

a:hover {
  color: #003f7e;
}

/* Global button styling */
button {
  font-family: Arial, sans-serif;
}